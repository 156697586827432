<div class="main-content">
  <div class="container-fluid">

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row justify-content-end" (click)="close()" style="cursor: pointer;">
            <em class="material-icons" style="color: #4a4a4a;">clear</em>
          </div>
          <div class="text-center">
            <i *ngIf="!noIcon" class="fa fa-trash text-danger"></i>
            <h3>{{ text }}</h3>
          </div>
          <div class="content">
            <div class="row">
              <div class="col-md text-center">
                <button type="button" class="btn btn-default" (click)="close()">{{cancelText}}</button>
                &nbsp;
                <button type="button" class="btn btn-danger btn-fill" (click)="delete()">{{continueText}}</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
