import { BaseModel } from "./base.model";
import { Address } from './address.model';
import { User } from './user.model';
import { jsonIgnore } from 'json-ignore';

export class Company extends BaseModel {
  nom: string;
  email: string;
  //adresse: Address;
  @jsonIgnore()
  exploitant: User;
  dateOuverture: string;
  dateFermeture: string;
  siret: string;
  location: string;
  category: string|Company.Categorie;
  categories: string[]|Company.Categorie[];
  erp: string[];
  categoriesDocumentsSuivis: any; // array of ids
  categoriesEquipementsSuivis: any; // array of ids
  allCategoriesDocumentsSetted: string[]; // array of ids
  customDelaiFormations: any; // array of ids
  documentsGeneralSetted: any; // array of ids, categori de doc
  equipementsSetted: any; // array of ids, categori de equip
  categoriesAppellation: any; // {id: appelation}
  @jsonIgnore()
  users: User[];
  @jsonIgnore()
  prepareUpdate() {
    if(this.category) {
      if (typeof this.category !== 'string') {
        this.category = ( this.category as Company.Categorie).id;
      }
    }
    if (this.categories && this.categories.length) {
      this.categories = (this.categories as any[]).map((i: any, index: number, arr: any) => {
        if (typeof i === 'string') {
          return i;
        } else {
          return (i as Company.Categorie).id;
        }
      });
    }
    return this;
  }

  constructor(arg?: any) {
    super(arg);

    if (arg) {
      /**if (arg.adresse) {
        this.adresse = new Address(arg.adresse);
      }**/
      if (arg.exploitant) {
        this.exploitant = new User(arg.exploitant);
      }
      if (arg.category && typeof arg.category !== 'string') {
        this.category = new Company.Categorie(arg.category);
      }
      if (arg.categories) {
        this.categories = arg.categories.map(i => {
          if (typeof i === 'string') {
            return i;
          } else {
            return new Company.Categorie(i);
          }
        })
      }
      if (arg.erp) {
        this.erp = arg.erp.map( i => i);
      }
    }
  }
}
export namespace Company {
  export class Categorie extends BaseModel {
    label: string;
  }
}
