import { BaseModel } from "./base.model";
import { jsonIgnore } from "json-ignore";
import * as moment from 'moment';
import { environment as ENV } from 'environments/environment';
import { EtablissementAutreJustificatif } from "./etablissement-autre-justificatif.model";

export class GeneralDocuments extends BaseModel {
  categorie: GeneralDocuments.Categorie;
  sousCategorieDocument: GeneralDocuments.SousCategorie;
  objet: string;
  referentiel: string;
  constructor(arg?: any) {
    super(arg);
  }
}
export namespace GeneralDocuments {
    export class Categorie  extends BaseModel {
        label: string;
        sousCategorieDocuments: GeneralDocuments.SousCategorie[];
        equipement: boolean;
        documentGeneraux: any[];
        @jsonIgnore()
        actif: boolean;
        @jsonIgnore()
        statut: string;
        @jsonIgnore()
        autreJustificatifs: {statut: StatutEnum, justificatifs: EtablissementAutreJustificatif[]};
        @jsonIgnore()
        get statusColor(): string  {
          switch (this.statut) {
            case StatutEnum.critique:
              return 'text-danger';
            case StatutEnum.conforme:
              return 'text-success';
            case StatutEnum.aCorriger:
              return 'text-warning';
            default:
              return 'text-default';
          }
        }
        get autreJustificatifsStatutColor(): string  {
          if (!this.autreJustificatifs) {
            return 'text-default';
          }
          if (!this.autreJustificatifs.statut) return 'text-default';
          switch (this.autreJustificatifs.statut) {
            case StatutEnum.critique:
              return 'text-danger';
            case StatutEnum.conforme:
              return 'text-success';
            case StatutEnum.aCorriger:
              return 'text-warning';
            default:
              return 'text-default';
          }
        }
        constructor(arg?: any) {
          super(arg);
          if (arg.sousCategorieDocuments) {
            this.sousCategorieDocuments = arg.sousCategorieDocuments.map(i => new GeneralDocuments.SousCategorie(i));
          }
        }
    }
    export class SousCategorie  extends BaseModel {
        label: string;
        categorieDocument: GeneralDocuments.Categorie;
        @jsonIgnore()
        justificatifs: GeneralDocuments.Justificatif[];
        @jsonIgnore()
        actif: boolean;
        @jsonIgnore()
        statut: string;
        @jsonIgnore()
        displayCustomCategory: boolean = false;
        @jsonIgnore()
        customCategoryName: string;
        @jsonIgnore()
        duplicataJustificatifs: Array<any>;
        @jsonIgnore()
        get statusColor(): string  {
          switch (this.statut) {
            case StatutEnum.critique:
              return 'text-danger';
            case StatutEnum.conforme:
              return 'text-success';
            case StatutEnum.aCorriger:
              return 'text-warning';
            default:
              return 'text-default';
          }
        }
        constructor(arg?: any) {
          super(arg);
          if (arg.categorieDocument) {
            this.categorieDocument = new GeneralDocuments.Categorie(arg.categorieDocument);
          }
          if (arg.justificatifs) {
            this.justificatifs = arg.justificatifs.map(j => new Justificatif(j))
          }
        }
    }
    export class Justificatif extends BaseModel {
      libelle: string;
      sousCategorie: GeneralDocuments.SousCategorie|string;
      delaiAlerte: GeneralDocuments.Valeur;
      frequenceTemporelle: GeneralDocuments.Valeur;
      frequenceTemporelleNonReglemente: GeneralDocuments.Valeur;
      frequenceEvenementielle: GeneralDocuments.Valeur;
      actionMenePar: string;
      referentiel: string;
      referentielAConsulter: string;
      equipement: boolean;
      statut: StatutEnum;
      archive: boolean;
      @jsonIgnore()
      suivi: boolean;
      @jsonIgnore()
      levee: GeneralDocuments.Levee;
      @jsonIgnore()
      fichierJustificatif: GeneralDocuments.Fichier;
      @jsonIgnore()
      ownId: string; // id related establissement
      dateLimit: string;
      dateAlert: string;
      @jsonIgnore()
      customFrequenceTemporelle: GeneralDocuments.Valeur;
      @jsonIgnore()
      get dateLimitValue(): string {
        if (this.dateLimit) {
          return moment(this.dateLimit).format('DD/MM/YYYY');
        }
        if (this.fichierJustificatif && this.fichierJustificatif.path && this.fichierJustificatif.date) {
          return moment(this.fichierJustificatif.date)
        .add(this.frequenceTemporelle.valeur as any, this.frequenceTemporelle.unite)
        .format('DD/MM/YYYY');
        } else {
          return 'N/A';
        }
      }
      @jsonIgnore()
      get statusColor(): string  {
        switch (this.statut) {
          case StatutEnum.critique:
            return 'text-danger';
          case StatutEnum.conforme:
            return 'text-success';
          case StatutEnum.aCorriger:
            return 'text-warning';
          default:
            return 'text-default';
        }
      }
      @jsonIgnore()
      get delaiFormat(): string {
        if (this.delaiAlerte) {
          const unit = ArrayUnite.find(i => i.id === this.delaiAlerte.unite);
          return this.delaiAlerte.valeur + ' ' + (unit ? unit.label : '');
        } else {
          return '';
        }
      }
      @jsonIgnore()
      get frequenceTemporelleFormat(): string {
        if (this.frequenceTemporelle) {
          if (this.frequenceTemporelle.valeur && this.frequenceTemporelle.unite) {
            const unit = ArrayUnite.find(i => i.id === this.frequenceTemporelle.unite);
            return this.frequenceTemporelle.valeur + ' ' + (unit ? unit.label : '');
          } else if (this.frequenceTemporelle.unite) {
            const unit = ArrayUnite.find(i => i.id === this.frequenceTemporelle.unite);
            return unit ? unit.label : '';
          }
        }
        return '';
      }
      @jsonIgnore()
      get frequenceEvenementielleFormat(): string {
        if (this.frequenceEvenementielle) {
          if (this.frequenceEvenementielle.valeur && this.frequenceEvenementielle.unite) {
            const unit = ArrayUniteEvent.find(i => i.id === this.frequenceEvenementielle.unite);
            return this.frequenceEvenementielle.valeur + ' ' + (unit ? unit.label : '');
          } else if (this.frequenceEvenementielle.unite) {
            const unit = ArrayUniteEvent.find(i => i.id === this.frequenceEvenementielle.unite);
            return unit ? unit.label : '';
          }
        }
        return '';
      }
      constructor(arg?: any) {
        super(arg);
        if (arg.delaiAlerte) {
          this.delaiAlerte = new Valeur(arg.delaiAlerte);
        }
        if (arg.frequenceEvenementielle) {
          this.frequenceEvenementielle = new Valeur(arg.frequenceEvenementielle);
        }
        if (arg.sousCategories) {
          this.sousCategorie = new SousCategorie(arg.sousCategories);
        }
        if (arg.frequenceTemporelleNonReglemente) {
          this.frequenceTemporelleNonReglemente = new Valeur(arg.frequenceTemporelleNonReglemente);
        }
        if (arg.frequenceTemporelle) {
          this.frequenceTemporelle = new Valeur(arg.frequenceTemporelle);
        }
      }
  }
  export enum UniteEnum {
    ponctuelle = 'ponctuelle',
    day = 'day',
    week = 'week',
    month = 'month',
    year = 'year',
  }
  export const ArrayUnite = [
    { id: GeneralDocuments.UniteEnum.day, label: 'Jours' },
    { id: GeneralDocuments.UniteEnum.week, label: 'Semaines' },
    { id: GeneralDocuments.UniteEnum.month, label: 'Mois' },
    { id: GeneralDocuments.UniteEnum.year, label: 'An' }
  ];
  export const ArrayUniteEvent = [
    { id: GeneralDocuments.UniteEnum.ponctuelle, label: 'Ponctuelle'},
    { id: GeneralDocuments.UniteEnum.day, label: 'Jours' },
    { id: GeneralDocuments.UniteEnum.week, label: 'Semaines' },
    { id: GeneralDocuments.UniteEnum.month, label: 'Mois' },
    { id: GeneralDocuments.UniteEnum.year, label: 'An' }
  ];
  export class Levee extends BaseModel {
    note: string;
    date: string;
    path: string;
    nom: string;
    fileId: string;
    resteLevee: boolean;
    @jsonIgnore()
    get filePath(): string {
      return ENV.DATA_ENDPOINT + '' + this.fileId;
    }
    constructor(arg) {
      super(arg);
    }
  }
  export class Fichier extends BaseModel {
    path: string;
    date: string;
    nom: string;
    fileId: string;
    resteLevee: boolean;
    levee: Levee[];
    dateLimit: string;
    @jsonIgnore()
    get filePath(): string {
      return ENV.DATA_ENDPOINT + '' + this.fileId;
    }
    constructor(arg) {
      super(arg);
    }
    removeSystemPropreties() {
      delete this.id;
      delete this._id;
      this.levee = this.levee.map(i => {
        delete i.id;
        delete i._id;
        return i;
      });
      return this;
    }
  }
  export class Valeur extends BaseModel {
    valeur: number;
    unite: string;
    @jsonIgnore()
    get formatted() {
      if (!this.valeur || !this.unite) {
        return 'N/A';
      }
      const unite = ArrayUnite.find(i => i.id == this.unite);
      return this.valeur + ' ' + (unite ? unite.label.toLocaleLowerCase() : this.unite);
    }
    constructor(arg) {
      super(arg);
    }
  }
  export enum StatutEnum {
    conforme = 'conforme',
    aCorriger = 'aCorriger',
    critique = 'critique',
    nonsuivi = 'nonsuivi',
    default = 'default'// non parametré
  }
}