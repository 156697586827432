import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpService } from '@app-providers/http.service';
import { EtablissementJustificatif } from '@app-models/etablissement-justificatif.model';

@Injectable({
  providedIn: 'root'
})
export class EtablissementJustificatifService extends BaseService<EtablissementJustificatif> {
  constructor(protected _httpService: HttpService) {
    super(_httpService, EtablissementJustificatif, "etablissement-justificatifs");
  }
}
