import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DataInfoModel {
  title: string;
  text: string;
}
@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})

export class InfoModalComponent  implements DataInfoModel {

  public text: string;
  public title: string;

  constructor(
    public dialogRef: MatDialogRef<InfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataInfoModel,
  ) {
    this.title = (this.data.title) ? this.data.title : 'Aide';
    this.text = (this.data.text) ? this.data.text : 'Ceci est une fenêtre d\'aide';
  }

  info(): void {
    this.dialogRef.close(true);
  }
  close(): void {
    this.dialogRef.close(false);
  }

}
