import { jsonIgnore, jsonIgnoreReplacer } from "json-ignore";
import { BaseModel } from "./base.model";
import { Role } from "./role.model";
import { Company } from './company.model';
import { Job } from './job.model';

export class User extends BaseModel {
  email: string;
  lastname: string;
  firstname: string;
  confirmed: boolean;
  blocked: boolean;
  password: string;
  role: Role|string;
  fonction: string;
  etablissement: Company|string;
  @jsonIgnore()
  repeatPassword;
  username: string;
  droit: User.Droit;
  poste: Job;
  toutesAlertes: boolean;
  admin: boolean;

  constructor(arg?: any) {
    super(arg);

    if (arg) {
      if (typeof arg.role !== 'string') {
        this.role = BaseModel.initModel(arg.role, Role);
      }
    }
  }

  @jsonIgnore()
  get name(): string {
    return `${this.firstname || ""} ${this.lastname || ""}` || this.username;
  }

  isValidEmail() {
    const regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return regex.test(this.email);
  }
  prepareForSending() {
    const copy: any = {};
    if (this.email) {
      const arr = this.email.split('@');
      this.username = arr[0] + arr[1].split('.')[0] + arr[1].split('.')[1];
    }
    try {
      const data = JSON.parse(JSON.stringify(this, jsonIgnoreReplacer));
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          if (data[key] === null) {
          } else {
            if (key === "extra") {
              copy[key] = this[key].prepareForSending();
            } else {
              copy[key] = data[key];
            }
          }
        }
      }
    } catch (e) {}
    return copy;
  }
}
export namespace User {
  export class Droit extends BaseModel {    
    equipementsInstallationsNotification:	boolean;
    documentsGenerauxParametrage:	boolean;
    documentsGenerauxNotification:	boolean;
    documentsGenerauxUtilisation:	boolean;
    equipementsInstallationsUtilisation:	boolean;
    equipementsInstallationsParametrage:	boolean;
    travailleursNotification:	boolean;
    travailleursParametrage:	boolean;
    travailleursUtilisation:	boolean;
    toutAlertes: boolean;
    admin: boolean;
  }
}
