import { BaseModel } from "./base.model";
import { jsonIgnore } from "json-ignore";
import { environment as ENV } from "environments/environment";

export class Fichier extends BaseModel {
  name: string;
  path: string;
  fileSize: number;
  mimeType: string;
  date: string;
  @jsonIgnore()
  folder: string;
  @jsonIgnore()
  get realPath(): string {
    return (
      ENV.DATA_ENDPOINT + "/" + this.path + "?folder=" + this.folder ||
      "produit"
    );
  }

  constructor(arg?: any, folder?) {
    super(arg);
    if (folder) {
      this.folder = folder;
    }
  }
}
