import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpService } from '@app-providers/http.service';
import { EtablissementAutreJustificatif } from '@app-models/etablissement-autre-justificatif.model';

@Injectable({
  providedIn: 'root'
})
export class EtablissementAutreJustificatifService extends BaseService<EtablissementAutreJustificatif> {
  constructor(protected _httpService: HttpService) {
    super(_httpService, EtablissementAutreJustificatif, "etablissement-autre-justificatifs");
  }
}
