import { Component, AfterViewInit, Inject, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from "@angular/forms";
import { ToasterService } from "@app-providers/toaster.service";
import { SessionService } from "app/shared/providers/session.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FileUploaderComponent } from 'app/components/file-uploader/file-uploader.component';
import { FileItem } from 'ng2-file-upload';
import { GeneralDocuments } from '@app-models/general-document.model';
import { environment as ENV } from 'environments/environment';

@Component({
  selector: "app-select-file-modal",
  templateUrl: "./select-file-modal.component.html",
  styleUrls: ["./select-file-modal.component.scss"]
})
export class SelectFileModalComponent implements AfterViewInit {
  isLoading: boolean = true;
  form: FormGroup;
  edition: boolean;
  isJustificatif: boolean = true;
  kind: string;
  @ViewChild(FileUploaderComponent, {static: false}) uploader: FileUploaderComponent;

  constructor(
    public dialogRef: MatDialogRef<SelectFileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private _toasterService: ToasterService,
    public session: SessionService,
  ) {
    this.isLoading = true;
    this.kind = this.data.kind || 'justificatif';
    this.edition = true;
  }

  async ngAfterViewInit() {
    setTimeout(() => {
      this.form = this.fb.group({
        nom: ["", Validators.required],
        date: ['', Validators.required],
        resteLevee: [false],
      });
    });
    this.isLoading = false;
  }

  async submit() {
    try {
      if (!this.uploader.uploader.queue.length) {
        this._toasterService.error('Veuillez insérer un fichier');
        return;
      }
      if (!this.form.valid) {
        this._toasterService.error('Veuillez remplir la formulaire');
        return;
      }
      this.isLoading = true;
      this.uploader.uploadFileNow();
    } catch (e) {
      console.error(e);
      this.isLoading = false;
    }
  }
  async error(response) {
    console.error(response);
    this.isLoading = false;
  }
  async done(response) {
    const file = this.kind === 'justificatif' ?
    new GeneralDocuments.Fichier({
      fileId: response.id,
      path: ENV.DATA_ENDPOINT + response.id,
      nom: this.form.value.nom,
      date: this.form.value.date,
      resteLevee: this.form.value.resteLevee,
    }) :
    new GeneralDocuments.Levee({
      fileId: response.id,
      path: ENV.DATA_ENDPOINT + response.id,
      nom: this.form.value.nom,
      date: this.form.value.date,
      resteLevee: this.form.value.resteLevee,
    });
    this.dialogRef.close(file);
  }
  onFileAdded(fileItem: FileItem) {
    console.log(fileItem);
    this.form.patchValue({nom: fileItem._file.name});
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  isFieldValid(field: any) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
  displayFieldCss(field: string) {
    return {
      "has-error": this.isFieldValid(field),
      "has-feedback": this.isFieldValid(field)
    };
  }
  reset() {
    this.form.reset();
  }
}
