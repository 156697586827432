import { Component, AfterViewInit, Inject, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from "@angular/forms";
import { ToasterService } from "@app-providers/toaster.service";
import { SessionService } from "app/shared/providers/session.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { GeneralDocuments } from '@app-models/general-document.model';
import { environment as ENV } from 'environments/environment';


@Component({
  selector: "app-levee-modal",
  templateUrl: "./levee-modal.component.html",
  styleUrls: ["./levee-modal.component.scss"]
})
export class LeveeModalComponent implements AfterViewInit {
  isLoading: boolean = true;
  form: FormGroup;
  edition: boolean;

  constructor(
    public dialogRef: MatDialogRef<LeveeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private _toasterService: ToasterService,
    public session: SessionService,
  ) {
    this.isLoading = true;
    this.edition = true;
  }

  async ngAfterViewInit() {
    setTimeout(() => {
      this.form = this.fb.group({
        note: ["", Validators.required],
        date: [new Date(), Validators.required],
        reserve: false
      });
    });
    this.isLoading = false;
  }

  async submit() {
    try {
      if (!this.form.valid) {
        this._toasterService.error('Veuillez remplir la formulaire');
        return;
      }
      this.dialogRef.close(new GeneralDocuments.Levee({
        note: this.form.value.note,
        date: this.form.value.date
      }));
    } catch (e) {
      console.error(e);
      this.isLoading = false;
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  isFieldValid(field: any) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }
  displayFieldCss(field: string) {
    return {
      "has-error": this.isFieldValid(field),
      "has-feedback": this.isFieldValid(field)
    };
  }
  reset() {
    this.form.reset();
  }
}
