import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Router } from '@angular/router';
import { SessionService } from '@app-providers/session.service';
import { User } from '@app-models/user.model';
import { LocalStorageService } from '@app-providers/local-storage.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Role } from '@app-models/role.model';
import { UtilsService } from '@app-providers/utils.service';

declare const $: any;

// Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  customIconType?: string;
  collapse?: string;
  children?: ChildrenItems[];
  autorisation?: string | string[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab?: string;
  type?: string;
  autorisation?: string | string[];
}
export const dashboard: RouteInfo =   {
  path: '/dashboard',
  title: 'Tableau de bord',
  type: 'link',
  icontype: 'pe-7s-graph'
};
// Menu Items
export const ROUTES: RouteInfo[] = [
  dashboard,
  {
    path: '/administration',
    title: 'Administration',
    type: 'link',
    icontype: 'pe-7s-note2'
  },
  {
    path: '/documents',
    title: 'Documents généraux site',
    type: 'sub',
    collapse: 'documents',
    icontype: 'pe-7s-folder',
    autorisation: ['documentsGenerauxParametrage', 'documentsGenerauxUtilisation', 'admin'],
    children: [
      {
        path: 'setting',
        title: 'Paramétrage',
        type: 'link',
        autorisation: ['documentsGenerauxParametrage', 'admin']
      }
    ]
  },
  {
    path: '/equipements',
    title: 'Equipements et installations',
    type: 'sub',
    collapse: 'equipements',
    icontype: 'pe-7s-file',
    autorisation: ['equipementsInstallationsUtilisation', 'equipementsInstallationsParametrage', 'admin'],
    children: [
      {
        path: 'setting',
        title: 'Paramétrage',
        type: 'link',
        autorisation: ['equipementsInstallationsParametrage', 'admin']
      }
    ]
  },
  {
    path: '/travailleurs',
    title: 'Travailleurs',
    type: 'sub',
    collapse: 'travailleurs',
    icontype: 'pe-7s-users',
    autorisation: ['travailleursParametrage', 'travailleursUtilisation', 'admin'],
    children: [
      {
        path: 'parametrage',
        title: 'Paramétrage travailleurs',
        type: 'link',
        autorisation: ['travailleursParametrage', 'admin']
      },
      {
        path: 'parametrage-formation',
        title: 'Paramétrage formations et documents en lien',
        type: 'link',
        autorisation: ['travailleursParametrage', 'admin']
      }
    ]
  }
];
export const ROUTES_2: RouteInfo[] = [
  {
    path: '/archives',
    title: 'Archives',
    type: 'link',
    icontype: 'pe-7s-server'
  },
  /**{
    path: '/toolbox',
    title: 'Boîte à outils',
    type: 'sub',
    collapse: 'outils',
    icontype: 'pe-7s-plugin',
    children: [
      {
        path: 'documents',
        title: 'Documents type',
        type: 'link',
      }
    ]
  }**/
];
@Component({
  selector: 'app-sidebar-cmp',
  templateUrl: 'sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  public menuItems: RouteInfo[];
  public menuItems2: RouteInfo[];
  public ps: any;
  public currentUser: User = {} as User;
  public role: string[];
  logoPath: any;
  public isLoading: boolean;
  public home: any = ['/'];

  constructor(
    public session: SessionService,
    private _utils: UtilsService,
    private _router: Router) {
      this.session.isAuthenticated.subscribe((isConnected) => {
          this.ngOnInit();
      });
    }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    this.isLoading = true;
    this.currentUser = this.session.user;

    const droit =  this.currentUser ? this.currentUser.droit : null;
    if (!this.currentUser) {
      return;
    }
    if ((this.currentUser.role as Role).type === 'exploitant') {
      this.menuItems = [...ROUTES];
    } else {
      if (!droit) {
        this.menuItems = [dashboard];
      } else {
        const droitArray = Object.entries(droit).filter(i => i[1]).map(i => i[0]);
        this.menuItems = JSON.parse(JSON.stringify(ROUTES)).filter(route => {
          if (Array.isArray(route.autorisation)) {
            return droitArray.some(i => route.autorisation.indexOf(i) > -1);
          } else {
            return droitArray.indexOf(route.autorisation) > -1;
          }
        }).map(route => {
          if (route.collapse) {
            route.children = route.children.filter( r => {
              if (Array.isArray(r.autorisation)) {
              return droitArray.some(i => r.autorisation.indexOf(i) > -1);
            } else {
              return droitArray.indexOf(r.autorisation) > -1;
            }
            });
            return route;
          } else {
            return route;
          }
        });
        this.menuItems.unshift(dashboard);
        this.home = this.menuItems[0].path;
      }
    }

    // this.menuItems2 = (this.currentUser.role as Role).type === 'exploitant' ?  ROUTES_2 : [];
    this.menuItems2 = ROUTES_2;
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>(
        document.querySelector('.sidebar .sidebar-wrapper')
      );
      if (elemSidebar) {
        this.ps = new PerfectScrollbar(elemSidebar);
      }
    }
    this.isLoading = false;
  }
  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }

  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
      navigator.platform.toUpperCase().indexOf('IPAD') >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  async logout() {
    try {
      const success = await this._utils.confirm('Voulez-vous vraiment vous deconnecter de l\'application?');
      if (success) {
        this.session.logout();
        this._router.navigate(['/auth', 'login']);
      }
    } catch (e) {
      console.error(e);
    }
  }
}
