import { GeneralDocuments } from './general-document.model';
import { BaseModel } from './base.model';
import { Company } from './company.model';
import * as moment from 'moment';
import { jsonIgnore } from 'json-ignore';

export class EtablissementJustificatif extends BaseModel {
    suivi: boolean;
    levee: GeneralDocuments.Levee;
    fichierJustificatif: GeneralDocuments.Fichier;
    delaiAlerte: GeneralDocuments.Valeur;
    justificatif: GeneralDocuments.Justificatif|string; // model or id
    etablissement: Company|string; // model or id
    historyFichierJustificatif: GeneralDocuments.Fichier[];
    statut: GeneralDocuments.StatutEnum;
    memo: string;
    dateLimit: string;
    dateAlert: string;
    frequenceTemporelleNonReglemente: GeneralDocuments.Valeur;
    @jsonIgnore()
    get statusColor(): string  {
      switch (this.statut) {
        case GeneralDocuments.StatutEnum.critique:
          return 'text-danger';
        case GeneralDocuments.StatutEnum.conforme:
          return 'text-success';
        case GeneralDocuments.StatutEnum.aCorriger:
          return 'text-warning';
        default:
          return 'text-default';
      }
    }
    @jsonIgnore()
    get delaiFormat(): string {
      if (this.delaiAlerte) {
        return this.delaiAlerte.valeur + ' ' + GeneralDocuments.ArrayUnite.find(i => i.id === this.delaiAlerte.unite).label;
      }
    }
    constructor(arg?: any) {
      super(arg);
      if (arg.levee) {
        this.levee = new GeneralDocuments.Levee(arg.levee);
      }
      if (arg.fichierJustificatif) {
        this.fichierJustificatif = new GeneralDocuments.Fichier(arg.fichierJustificatif);
      }
      if (arg.historyFichierJustificatif) {
        this.historyFichierJustificatif = arg.historyFichierJustificatif.map(i => new GeneralDocuments.Fichier(i));
      }
      if (arg.delaiAlerte) {
        this.delaiAlerte = new GeneralDocuments.Valeur(arg.delaiAlerte);
      }
      if (arg.frequenceTemporelleNonReglemente) {
        this.frequenceTemporelleNonReglemente = new GeneralDocuments.Valeur(arg.frequenceTemporelleNonReglemente);
      }
    }
}