<div class="main-content">
  <div class="container-fluid">

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row justify-content-end" (click)="close()" style="cursor: pointer;">
            <em class="material-icons" style="color: #4a4a4a;">clear</em>
          </div>
          <div class="text-center">
            <h3 class="mt-1">   <i class="fa fa-info-circle text-primary"></i>  {{ title }}</h3>
            <p>{{ text }}</p>
          </div>
          <div class="content">
            <div class="row">
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
