import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
const getOptions = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};
@Injectable({
  providedIn: 'root'
})
export class BasicAuthInterceptor implements HttpInterceptor {
  constructor(
    private _localStorageService: LocalStorageService,
    private router: Router
  ) {

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with bearer auth credentials if available
    const token: string = this._localStorageService.get('token');
    if (token) {
      request = request.clone({
        setHeaders: getOptions(token)
      });
    }
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
              console.error("Error Event");
          } else {
              switch (error.status) {
                  case 0:
                      this.router.navigateByUrl('/error/default');
                      break;
                  /*case 401:
                      this.router.navigateByUrl("/login");
                      break;*/
                  case 403:
                      this.router.navigateByUrl("/error/unauthorized");
                      break;
              }
          }
        } else {
            console.error("some thing else happened");
        }
        return throwError(error);
      })
    );
  }
}
