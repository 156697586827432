import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from '@app-guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'error',
    loadChildren: '../pages/error/error.module#ErrorModule',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: '../pages/dashboard/dashboard.module#DashboardModule',
      },
      {
        path: 'administration',
        canActivate: [AuthGuard],
        loadChildren: '../pages/administration/administration.module#AdministrationModule',
      },
      {
        path: 'documents',
        canActivate: [AuthGuard],
        loadChildren: '../pages/document/document.module#DocumentModule',
      },
      {
        path: 'equipements',
        canActivate: [AuthGuard],
        loadChildren: '../pages/equipment/equipment.module#EquipmentModule',
      },
      {
        path: 'travailleurs',
        canActivate: [AuthGuard],
        loadChildren: '../pages/travailleurs/travailleurs.module#TravailleursModule',
      },
      {
        path: 'archives',
        canActivate: [AuthGuard],
        loadChildren: '../pages/archives/archives.module#ArchivesModule',
      },
      {
        path: 'toolbox',
        canActivate: [AuthGuard],
        loadChildren: '../pages/toolbox/toolbox.module#ToolboxModule',
      }
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: '../auth/auth.module#AuthModule',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule { }
