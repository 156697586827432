import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { environment } from 'environments/environment';
import { Fichier } from '@app-models/fichier.model';
import { UtilsService } from '@app-providers/utils.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorageService } from '@app-providers/local-storage.service';
@Component({
  selector: "app-file-uploader",
  templateUrl: "./file-uploader.component.html",
  styleUrls: ["./file-uploader.component.scss"]
})
export class FileUploaderComponent implements OnInit {
  public uploader: FileUploader;
  isLoading: boolean;
  nameFile; string;
  @Input() endpoint: string;
  @Input() fichier: Fichier;
  @Input() edition: boolean;
  @Input() maxWidth: string = '200px';
  @Output() onFileAdded: EventEmitter<Object> = new EventEmitter();
  @Output() onDone: EventEmitter<Object> = new EventEmitter();
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  constructor(
    private utils: UtilsService,
    private sanitizer: DomSanitizer,
    private _localStorageService: LocalStorageService
  ) {
    this.isLoading = false;
  }

  ngOnInit() {
    this.uploader = this.loadFileUploader(this.endpoint, {});
  }
  /**
   * load uploader
   */
  loadFileUploader(endpoint, options?: any): FileUploader {
    const additionalParameter = {
      fileInfo: JSON.stringify({
        alternativeText: '', caption: '', name: null
      })
    };
    for (const key in options) {
      if (options[key]) {
        additionalParameter[key] = options[key];
      }
    }
    const uploader = new FileUploader({
      url: environment.FILE_ENDPOINT,
      headers: [
        {
          name: 'Authorization',
          value: 'Bearer ' + this._localStorageService.get('token')
        },
      ],
      itemAlias: "files",
      isHTML5: true
    });
    uploader.setOptions({
      isHTML5: true,
      additionalParameter: additionalParameter
    });
    uploader.onBeforeUploadItem = (item: FileItem) => {
      this.isLoading = true;
      item.withCredentials = false;
    };
    uploader.onErrorItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders
    ) => {
      // console.log(item, status, headers);
      this.onError.emit(JSON.parse(response));
    };

    uploader.onAfterAddingFile = (fileItem: FileItem) => {
      // Remove first element every time a new file is adding to limit to 1 element in queue
      this.onFileAdded.emit(fileItem);
      if (uploader.queue.length > 1) {
        uploader.queue.shift();
      }
      this.nameFile = fileItem.file.name;
    };
    uploader.onCompleteItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders
    ) => {
      try {
        const jsonResponse = JSON.parse(response);
        // empty the queue
        uploader.clearQueue();
        this.endUploaded(jsonResponse);
      } catch (e) {
        console.log(e);
      }
    };
    return uploader;
  }
  /**
   *  should be fire when upload are done
   */
  public endUploaded(resp) {
    if (this.uploader.queue.length === 0) {
      // todo
      this.isLoading = false;
      this.onDone.emit(resp[0]);
    }
  }
  public uploadFileNow() {
    if (this.uploader.getNotUploadedItems().length > 0) {
      this.uploader.uploadAll();
    } else {
      this.endUploaded(true);
    }
  }
  getFilePath(file: Fichier) {
    return this.utils.getfile(file.path);
  }
  previewFileItem(fileItem: FileItem) {
    return this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
  }
}
