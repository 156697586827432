import { Injectable, NgZone } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleApiService {
  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone) {

  }
  public geocodeAddress(body, geocoderType?): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.mapsAPILoader.load().then(() => {
        const geocoder = new google.maps.Geocoder();
        const callback = (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            resolve(results[0]);
          } else {
            reject(status);
          }
        };
        if (body && geocoder) {
          if (geocoderType && geocoderType === 'location') {
            geocoder.geocode(
              {
                location: body // {lng: float, lat: float}
              },
              callback
            );
          } else {
            geocoder.geocode(
              {
                address: body
              },
              callback
            );
          }
        } else {
          reject();
        }
      });
    });
  }

  public getAddressPredictions(model): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.mapsAPILoader.load().then(() => {
        const acs = new google.maps.places.AutocompleteService();
        const config = {
          input: model,
          bounds: undefined,
          types: ['address'],
          componentRestrictions: {
            country: 'fr'
          }
        };
        acs.getPlacePredictions(config, (results) => {
          if (results && results.length > 0) {
            resolve(results);
          } else {
            console.log('Address predictions: no results');
            reject();
          }
        });
      });
    });
  }
  public getLocalityPredictions(model: any, options?: any): Promise<any> {
    options = options || {};
    return new Promise<any>((resolve, reject) => {
      this.mapsAPILoader.load().then(() => {
        const acs = new google.maps.places.AutocompleteService();
        options = {
          input: model,
          // bounds: undefined,
          types: ['(cities)'],
          componentRestrictions: options.restrictions || {
            country: 'fr'
          }
        };
        acs.getPlacePredictions(options, (results) => {
          if (results && results.length > 0) {
            resolve(results);
          } else {
            console.log('Address predictions: no results');
            resolve(false);
          }
        });
      });
    });
  }

  public formatPlaceAddress(place: any, model?: any) {
    const body = model || {};

    if (place && place.place_id) {
      // body.google_id = place.place_id;
      body.label = place.formatted_address;
      body.location = {
        type: 'Point',
        coordinates : [
          place.geometry.location.lng(),
          place.geometry.location.lat()
        ]
      };

      for (const i in place.address_components) {
        if (
          place.address_components[i].types &&
          place.address_components[i].long_name
        ) {
          for (const type of place.address_components[i].types) {
            switch (type) {
              case 'locality':
                body.city = place.address_components[i].long_name;
                break;
              case 'postal_code':
                body.postalCode = place.address_components[i].long_name;
                break;
              case 'country':
                body.country = place.address_components[i].long_name;
                break;
              case 'street_number':
                body.street1 = body.street1 || '';
                body.street1 += place.address_components[i].long_name + ' ';
                break;
              case 'route':
                body.street1 = body.street1 || '';
                body.street1 += place.address_components[i].long_name;
                break;
            }
          }
        }
      }
    }

    return body;
  }

  public getPlaceById(placeId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (placeId) {
        this.mapsAPILoader.load().then(() => {
          const placesService = new google.maps.places.PlacesService(
            document.createElement('input')
          );
          placesService.getDetails(
            {
              placeId: placeId
            },
            (place, status) => {
              if (status === google.maps.places.PlacesServiceStatus.OK) {
                resolve(place);
              } else {
                reject();
              }
            }
          );
        });
      } else {
        reject();
      }
    });
  }
}
