// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  LOCAL_STORAGE_PREFIX: "f8e5c41c-d071-4d9f-9cf8-9fdaa9d5d2a2",
  DOMAIN_SCHEME: "https://",
  ENV: "dev",
  DOMAIN: "adoka",
  API_URL: "https://adoka-app.osc-fr1.scalingo.io/",
  SERVER_URL: "https://adoka-app.osc-fr1.scalingo.io",
  FILE_ENDPOINT: "https://adoka-app.osc-fr1.scalingo.io/upload",
  DATA_ENDPOINT: "https://adoka-app.osc-fr1.scalingo.io/upload/getFile/",
  GOOGLE_MAPS_API_KEY: "AIzaSyDwExEhB93pglsoAjD8wFjjAy2sWZzLPgw"
};
