import { BaseModel } from "./base.model";
import { jsonIgnore } from "json-ignore";

export class Role extends BaseModel {
  name: string;
  description: string;
  type: string;
  permissions: string[]
  autorisations: string[] = [];
  constructor(arg?: any) {
    super(arg);
  }
}
