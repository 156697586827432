<div class="logo">
  <a [routerLink]="home" class="simple-text logo-normal">
      <img src="./assets/img/logo.jpeg" alt="" style="max-width: 120px; height: auto; object-fit: cover;" />
  </a>
</div>

<div class="sidebar-wrapper">
  <div class="user">
    <div class="user-info text-center">
      <!--ul class="nav">
        <li class="nav-item nav-logout-li">
          <a href="javascript:void(0)" (click)="logout()" class="nav-link nav-logout">
            <span class="sidebar-normal">Deconnexion</span>
          </a>
        </li>
      </ul-->
      <div>
        <h4 class="company-title" *ngIf="session && session.company && session.company.nom">{{session.company.nom}}</h4>
      </div>
      <div class="navbar-username">
        <span>
          {{ currentUser?.firstname | capitalize}}</span> &nbsp;<span style="text-transform: uppercase;">{{ currentUser?.lastname | capitalize }}
            <a href="javascript:void(0)" (click)="logout()" >
              <i class="fa fa-sign-out"></i>
            </a>
        </span>
      </div>
      <!--div class="collapse" id="collapseExample">
        <ul class="nav">

          <li class="nav-item">
            <a href="javascript:void(0)" (click)="logout()" class="nav-link">
              <span class="sidebar-normal">Deconnexion</span>
            </a>
          </li>
        </ul>
      </div-->
    </div>
  </div>
  <ul class="nav" *ngIf="!isLoading">
    <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
      <!--If is a single link-->
      <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" class="nav-link">
        <i [class]="menuitem.icontype"></i>
        <p>{{menuitem.title}}</p>
      </a>
      <!--If it have a submenu-->
      <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'sub'" (click)="updatePS()"
        class="nav-link">
        <i [class]="menuitem.icontype"></i>
        <p>{{menuitem.title}}</p>
      </a>

      <!--Display the submenu items-->
      <div id="{{menuitem.collapse}}" class="collapse show" *ngIf="menuitem.type === 'sub'">
        <ul class="nav">
          <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
            <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link">
              <span class="sidebar-mini">{{childitem.ab}}</span>
              <span class="sidebar-normal">{{childitem.title}}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
  <ul class="nav">
    <li routerLinkActive="active" *ngFor="let menuitem of menuItems2" class="nav-item">
      <!--If is a single link-->
      <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" class="nav-link">
        <i [class]="menuitem.icontype"></i>
        <p>{{menuitem.title}}</p>
      </a>
      <!--If it have a submenu-->
      <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'sub'" (click)="updatePS()"
        class="nav-link">
        <i [class]="menuitem.icontype"></i>
        <p>{{menuitem.title}}</p>
      </a>

      <!--Display the submenu items-->
      <div id="{{menuitem.collapse}}" class="collapse show" *ngIf="menuitem.type === 'sub'">
        <ul class="nav">
          <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
            <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link">
              <span class="sidebar-mini">{{childitem.ab}}</span>
              <span class="sidebar-normal">{{childitem.title}}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
