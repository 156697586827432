import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { DeleteModalComponent } from 'app/components/delete-modal/delete-modal.component';
import { environment } from 'environments/environment';
import { GeneralDocuments } from '@app-models/general-document.model';
import * as moment from 'moment';
import { QuitModalComponent } from 'app/components/quit-modal/quit-modal.component';

declare var $: any;
declare var swal: any;

@Injectable({providedIn: 'root'})
export class UtilsService {
  constructor(
    private _location: Location,
    private _domSanitizationService: DomSanitizer,
    private dialog: MatDialog
  ) {}
  renewDuration(item: any) {
    if (!item) {
      return;
    }
    delete item.id;
    delete item._id;
    return item;
  }
  confirm(text?: string): Promise<boolean> {
    if (!text) {
      text = 'Voulez-vous vraiment continuer à réaliser cette action?';
    }
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(DeleteModalComponent, {
        width: '450px',
        data: text
      });
      dialogRef.afterClosed().subscribe(result => {
        resolve(result);
      });
    });
  }
  confirmQuit(text?: string): Promise<boolean> {
    if (!text) {
      text = 'Voulez-vous vraiment continuer à réaliser cette action?';
    }
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(QuitModalComponent, {
        width: '450px',
        data: text
      });
      dialogRef.afterClosed().subscribe(result => {
        resolve(result);
      });
    });
  }
  popupSuccess(message?) {
    return swal({
      title: 'Succès!',
      text: message || 'Traitement réussi.',
      type: 'success',
      confirmButtonClass: 'btn btn-success',
      buttonsStyling: false
    });
  }
  back() {
    return this._location.back();
  }

  getfile(path, folder?) {
    return this._domSanitizationService.bypassSecurityTrustResourceUrl(
      environment.DATA_ENDPOINT + path + (folder ? '?folder=' + folder : '')
    );
  }
  getStatut(fichierJustificatif: GeneralDocuments.Fichier, dateLimit?): GeneralDocuments.StatutEnum {
    const simpleStatut = () => {
        let resteLevee = fichierJustificatif.resteLevee || false;
        if (fichierJustificatif.levee && fichierJustificatif.levee.length > 0) {
          // get the last inserted
          resteLevee = fichierJustificatif.levee[fichierJustificatif.levee.length - 1].resteLevee || false;
        }
        return resteLevee ? GeneralDocuments.StatutEnum.aCorriger : GeneralDocuments.StatutEnum.conforme;
    };

    if (dateLimit) {
      const limit = moment(dateLimit);
      const now = moment();
      if (now.isAfter(limit)) {
        return  GeneralDocuments.StatutEnum.critique;
      } else {
        return simpleStatut();
      }
    } else {
      return simpleStatut();
    }
  }

}
