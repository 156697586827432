<div class="row" class="fileinput fileinput-new text-center" data-provides="fileinput">
    <!--div class="col-md-12">
        <div>
            <div class="fileinput-new thumbnail">
                <img *ngIf="!uploader.queue.length && fichier && fichier.path" [src]="getFilePath(fichier)" alt="..." style="max-width: 150px">
                <img *ngIf="!uploader.queue.length && !fichier" [style.max-width]="maxWidth" src="./assets/img/image_placeholder.jpg" alt="...">
                <img *ngIf="uploader.queue.length" [src]="previewFileItem(uploader.queue[0])" alt="..." style="max-width: 150px">
            </div>
            <div class="fileinput-preview fileinput-exists thumbnail"></div>
        </div>
    </div-->
    <div class="col-md-12" *ngIf="uploader">
        <div>
            <label *ngIf="uploader.queue.length">{{nameFile}}</label>
            <span class="btn btn-fill btn-warning btn-file" (click)="uploadInput.click()">
                <span class="fileinput-new">Parcourir</span>
                <span class="fileinput-exists">Modifier le fichier</span>
                <input #uploadInput [disabled]="isLoading" type="file"
                    accept=".png, .jpg, .jpeg, .gif, .pdf, .doc, .docx, .xls"  [hidden]="true" ng2FileSelect [uploader]="uploader" />
            </span>
            <br />
            <a href="javascript:void()" *ngIf="!isLoading"
                class="btn btn-danger btn-fill fileinput-exists" data-dismiss="fileinput">
                <i class="fa fa-times"></i> Supprimer</a>
        </div>
    </div>
    <div class="col-md-12" *ngIf="edition && uploader.queue.length && isLoading">
        <div class="progress" style="height: 10px">
            <div class="progress-bar bg-info progress-bar-striped" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }">
                {{uploader.progress ? uploader.progress : 0}} %
            </div>
        </div>
    </div>
</div>