import { GeneralDocuments } from './general-document.model';
import { BaseModel } from './base.model';
import { Company } from './company.model';
import * as moment from 'moment';
import { jsonIgnore } from 'json-ignore';

export class EtablissementAutreJustificatif extends BaseModel {
    objet: string;
    justificatif: GeneralDocuments.Justificatif|string; // model or id
    actionMenePar: string;
    frequenceTemporelle: GeneralDocuments.Valeur;
    delaiAlerte: GeneralDocuments.Valeur;
    frequenceEvenementielle: GeneralDocuments.Valeur;
    etablissement: Company|string; // model or id
    equipement: boolean;
    fichierJustificatif: GeneralDocuments.Fichier;
    historyFichierJustificatif: GeneralDocuments.Fichier[];
    archive: boolean;
    suivi: boolean;
    statut: GeneralDocuments.StatutEnum;
    dateAlert: string;
    dateLimit: string;
    memo: string;
    categoryId?: string;
    sousCategoryId?: string;
    @jsonIgnore()
    get dateLimitValue(): string {
      if (this.dateLimit) {
        return moment(this.dateLimit).format('DD/MM/YYYY');
      }
      if (this.fichierJustificatif && this.fichierJustificatif.path && this.fichierJustificatif.date) {
        return moment(this.fichierJustificatif.date)
      .add(this.frequenceTemporelle.valeur as any, this.frequenceTemporelle.unite)
      .format('DD/MM/YYYY');
      } else {
        return 'N/A';
      }
    }
    @jsonIgnore()
    get statusColor(): string  {
      switch (this.statut) {
        case GeneralDocuments.StatutEnum.critique:
          return 'text-danger';
        case GeneralDocuments.StatutEnum.conforme:
          return 'text-success';
        case GeneralDocuments.StatutEnum.aCorriger:
          return 'text-warning';
        default:
          return 'text-default';
      }
    }
    @jsonIgnore()
    get delaiFormat(): string {
      if (this.delaiAlerte) {
        return this.delaiAlerte.valeur + ' ' + GeneralDocuments.ArrayUnite.find(i => i.id === this.delaiAlerte.unite).label;
      }
    }
    @jsonIgnore()
      get frequenceTemporelleFormat(): string {
        if (this.frequenceTemporelle) {
          if (this.frequenceTemporelle.valeur && this.frequenceTemporelle.unite) {
            const unit = GeneralDocuments.ArrayUnite.find(i => i.id === this.frequenceTemporelle.unite);
            return this.frequenceTemporelle.valeur + ' ' + (unit ? unit.label : '');
          } else if (this.frequenceTemporelle.unite) {
            const unit = GeneralDocuments.ArrayUnite.find(i => i.id === this.frequenceTemporelle.unite);
            return unit ? unit.label : '';
          }
        }
        return '';
      }
      @jsonIgnore()
      get frequenceEvenementielleFormat(): string {
        if (this.frequenceEvenementielle) {
          if (this.frequenceEvenementielle.valeur && this.frequenceEvenementielle.unite) {
            const unit = GeneralDocuments.ArrayUniteEvent.find(i => i.id === this.frequenceEvenementielle.unite);
            return this.frequenceEvenementielle.valeur + ' ' + (unit ? unit.label : '');
          } else if (this.frequenceEvenementielle.unite) {
            const unit = GeneralDocuments.ArrayUniteEvent.find(i => i.id === this.frequenceEvenementielle.unite);
            return unit ? unit.label : '';
          }
        }
        return '';
      }
    constructor(arg?: any) {
      super(arg);
      if (arg.fichierJustificatif) {
        this.fichierJustificatif = new GeneralDocuments.Fichier(arg.fichierJustificatif);
      }
      if (arg.delaiAlerte) {
        this.delaiAlerte = new GeneralDocuments.Valeur(arg.delaiAlerte);
      }
      if (arg.frequenceEvenementielle) {
        this.frequenceEvenementielle = new GeneralDocuments.Valeur(arg.frequenceEvenementielle);
      }
      if (arg.frequenceTemporelle) {
        this.frequenceTemporelle = new GeneralDocuments.Valeur(arg.frequenceTemporelle);
      }
    }
}