<div class="row justify-content-end" (click)="dialogRef.close()" style="cursor: pointer;">
        <em class="material-icons" style="color: #4a4a4a;">clear</em>
</div>
<h4 class="text-center">
    Attestations de levée de réserves
</h4>
<div class="card mt-2 mb-2">
    <div class="card-body">            
        <div class="row" >
            <table class="table ">
                <thead>
                  <tr>
                    <th class="text-center">Attestation</th>
                    <!--th class="text-center"></th-->
                    <th class="text-center">Date</th>
                    <th class="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of levees; let i = index;">
                    <td class="text-left text-primary">
                        <a class="text-primary" [href]="item.filePath" target="_blank">
                            {{item.nom}}
                        </a>
                    </td>
                    <td class="text-center">
                      {{item.date}}
                    </td>                                            
                    <td class="td-actions text-center">
                        <a class="btn text-danger btn-link btn-just-icon btn-xs" (click)="delete(item, i)"><i class="fa fa-trash"></i></a>
                    </td>                         
                  </tr>
                </tbody>
            </table>    
        </div>        
    </div>
</div>