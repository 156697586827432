import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-quit-modal',
  templateUrl: './quit-modal.component.html',
  styleUrls: ['./quit-modal.component.scss']
})
export class QuitModalComponent {

  private formValue: string;
  public text: string;
  public noIcon: boolean;
  public cancelText: string = 'Annuler';
  public continueText: string = 'OK';
  constructor(
    public dialogRef: MatDialogRef<QuitModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
    this.text = (this.data) ? this.data : 'Êtes-vous sûr de vouloir supprimer cet élément ?';

  }

  quit(): void {
    this.dialogRef.close(true);
  }
  close(): void {
    this.dialogRef.close(false);
  }

}
