import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from './button/button.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { AutocompleteAddressComponent } from './autocomplete-address/autocomplete-address.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { FileUploadModule } from 'ng2-file-upload';
import { InfoButtonComponent } from './info-button/info-button.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { ModalListComponent } from './modal-list/modal-list.component';
import { LeveeModalComponent } from './levee-modal/levee-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { SelectFileModalComponent } from './select-file-modal/select-file-modal.component';
import { SelectFileNoLeveModalComponent } from './select-file-no-leve-modal/select-file-no-leve-modal.component';
import { QuitModalComponent } from './quit-modal/quit-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    MatInputModule,
    MatDatepickerModule
  ],
  declarations:
    [
      ButtonComponent,
      DeleteModalComponent,
      InfoModalComponent,
      AutocompleteAddressComponent,
      FileUploaderComponent,
      InfoButtonComponent,
      ModalListComponent,
      LeveeModalComponent,
      SelectFileModalComponent,
      SelectFileNoLeveModalComponent,
      QuitModalComponent
    ],
  exports:
    [
      ButtonComponent,
      DeleteModalComponent,
      AutocompleteAddressComponent,
      FileUploaderComponent,
      InfoModalComponent,
      InfoButtonComponent,
      LeveeModalComponent,
      SelectFileModalComponent,
      SelectFileNoLeveModalComponent,
      QuitModalComponent
    ],
  entryComponents: [
    InfoModalComponent,
    DeleteModalComponent,
    ModalListComponent,
    LeveeModalComponent,
    SelectFileModalComponent,
    SelectFileNoLeveModalComponent,
    QuitModalComponent
  ]
})
export class ComponentsModule { }
