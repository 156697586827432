import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/toPromise';
import { HttpService } from './http.service';
import { LocalStorageService } from './local-storage.service';
import { User } from '@app-models/user.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { ReplaySubject, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Company } from '@app-models/company.model';
import { Role } from '@app-models/role.model';
const jwt = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private checkIsInProgress = false;
  public user: User = {} as User;
  public company: Company = {} as Company;

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  libelleRole: string;
  role: Role;
  autorisations: string[] = [];

  constructor(
    private _httpService: HttpService,
    private _localStorageService: LocalStorageService
  ) {
  }

  getSessionStatus(): Observable<boolean> {
    return Observable.create(async (observer) => {
      try {
        const token: string = this._localStorageService.get('token');

        if (!token) {
          this.logout();
          observer.next(false);
        } else {
          try {
            await this.initSession(token);
          } catch (e) {
            observer.next(false);
          }
          observer.next(true);
        }
      } catch (e) {
        observer.next(false);
      }
      observer.complete();
    });

  }

  async initSession(token: string) {
    return new Promise(async (resolve, reject) => {
      this._localStorageService.set('token', token);
      try {
        // Check if token is expired
        if (jwt.isTokenExpired(token)) {
          this.logout();
          reject('token expired');
          return;
        }

        // Retrieve user data
        if (!this.user || !this.user.id || !this.autorisations && !this.autorisations.length) {
          const user = await this._httpService.get(`users/me`).toPromise();
          // Set current user data into observable
          if (!this.company || !this.company.id) {
            const company = await this._httpService.get(`etablissements/${user.etablissement}`).toPromise();
            user.etablissement = new Company(company);
          }
          this.setAuth(new User(user));
        }
        // this.programNextRegenerate();
        resolve(true);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }
  async updateCompany(company: Company) {
    try {
       this.company = company;
       company.prepareUpdate();
       await this._httpService.put(`etablissements/${this.company.id}`, company.prepareForSending()).toPromise();
    } catch (e) {
      console.error(e);
    }
  }

  login(user: any): Observable<any> {
    return this._httpService.post('auth/local', user).pipe(
      tap(async sessionUser => {
        if (!sessionUser || !sessionUser.user || !sessionUser.user.etablissement) {
          this.logout();
          return throwError('refused');
        }
        await this.initSession(sessionUser.jwt);
      })
    );
  }

  forgotPassword(data: any): Observable<any> {
    return this._httpService.post('users-permissions/customForgot'  , data);
  }

  public logout() {
    this.role = null;
    this.autorisations = [];
    this._localStorageService.removeAll();
    this.purgeAuth();
  }

  regenerate(token: string): Observable<any> {
    return Observable.create(async (observer) => {
      try {
        const datas = await this._httpService.get('auth/regenerate?token=' + token).toPromise();
        await this.initSession(datas.token);
        observer.next(datas);
      } catch (e) {
        observer.error(e);
      }
      observer.complete();
    });
  }

  setAuth(user: User) {
    // Set current user
    this.user = user;
    this.company = user.etablissement as Company;
    if (this.company && typeof this.company !== 'string') {
      this.company.category = this.company.category as Company.Categorie;
    }
    this.role = new Role(user.role);
    this.libelleRole = (user.role as Role).name;
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Set current user to an empty object
    this.user = undefined;
    this.autorisations = [];
    this.role = null;
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }

  private programNextRegenerate = () => {
    if (this.checkIsInProgress === true) {
      return;
    }
    const token = this._localStorageService.get('token');
    if (token) {
      const expirationDate = moment(jwt.getTokenExpirationDate(token)).subtract(10, 'minutes');
      this.checkIsInProgress = true;
      let delay = (expirationDate.valueOf() - moment().valueOf()) / 1000;

      if (delay < 10) {
        delay = 10;
      }

      setTimeout(async () => {
        try {
          // await this.regenerate(token);
        } catch (e) {
          console.log(e);
        }

        this.checkIsInProgress = false;

        this.programNextRegenerate();
      }, delay * 1000);
    }
  }

}
