<div class="row justify-content-end" (click)="dialogRef.close()" style="cursor: pointer;">
        <em class="material-icons" style="color: #4a4a4a;">clear</em>
</div>
<h4 class="text-center">
    Ajouter {{kind === 'justificatif' ?  'un justificatif' : 'une levée de réserves'}}
</h4>
<div class="card mt-2 mb-2">
    <div class="card-body" *ngIf="form">
        <form class="detail-form" [formGroup]="form" (ngSubmit)="submit()" novalidate>
            <div class="row" [ngClass]="displayFieldCss('nom')">
                <label class="col-md-3 col-form-label">Nom</label>
                <div class="col-md-9 form-group">
                    <input class="form-control" placeholder="Nom" type="text" formControlName="nom">
                </div>
            </div>
            <div class="row" [ngClass]="displayFieldCss('date')">
                <label class="col-md-3 col-form-label">Date du document</label>
                <div class="col-md-9 form-group">
                    <input class="form-control date-inline" matInput [matDatepicker]="picker" placeholder="Date du document" type="text" formControlName="date">
                    <mat-datepicker-toggle matSuffix [for]="picker" class="date-inline-icon"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 form-group text-center">
                    <app-file-uploader #uploader
                    (onFileAdded)="onFileAdded($event)"
                    (onError)="error($event)"
                      (onDone)="done($event)">
                    </app-file-uploader>
                </div>
            </div>
            <div class="row" [ngClass]="displayFieldCss('resteLevee')">                
                <div class="col-md-12 form-group text-center" >
                <label class="label-on-left inline">Reste t il d'autres documents?</label>
                <div class="custom-control custom-switch inline pl-2 pt-2">
                    <input type="checkbox" class="custom-control-input" id="customSwitch1" formControlName="resteLevee" style="transform: scale(4);">
                    <label class="custom-control-label" for="customSwitch1"></label>
                </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center mt-3">
                    <button class="btn btn-fill btn-default mr-1" [disabled]="isLoading" (click)="dialogRef.close()" type="button">Annuler</button>
                    <button class="btn btn-fill btn-primary ml-1" [disabled]="isLoading" type="submit">Enregistrer</button>
                </div>
            </div>
        </form>
    </div>
</div>