import { Component, AfterViewInit, Inject, ViewChild, OnInit } from "@angular/core";
import { ToasterService } from "@app-providers/toaster.service";
import { SessionService } from "app/shared/providers/session.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { UtilsService } from "@app-providers/utils.service";
import { GeneralDocuments } from '@app-models/general-document.model';
import { EtablissementJustificatifService } from '@app-services/etablissement-justificatif.service';
import { EtablissementJustificatif } from '@app-models/etablissement-justificatif.model';
import * as moment from 'moment';
import { EtablissementAutreJustificatifService } from "@app-services/etablissement-autre-justificatif.service";
import { EtablissementAutreJustificatif } from "@app-models/etablissement-autre-justificatif.model";
interface DataModel {
  item: EtablissementJustificatif|EtablissementAutreJustificatif;
  levees: GeneralDocuments.Levee[];
  autre?: boolean;
}
@Component({
  selector: "app-modal-list",
  templateUrl: "./modal-list.component.html",
  styleUrls: ["./modal-list.component.scss"]
})
export class ModalListComponent implements OnInit {
  isLoading: boolean = true;
  levees: GeneralDocuments.Levee[];
  constructor(
    public dialogRef: MatDialogRef<ModalListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataModel,
    public session: SessionService,
    private _utils: UtilsService,
    private _toasterService: ToasterService,
    private _etablissementJustificatifService: EtablissementJustificatifService,
    private _etablissementAutreJustificatifService: EtablissementAutreJustificatifService
  ) {
    this.isLoading = true;
    this.levees = this.data.levees.map(i => new GeneralDocuments.Levee(i));
  }

  async ngOnInit() {
    this.isLoading = false;
  }
  async delete(levee: GeneralDocuments.Levee, index: number) {
    try {
      const success = await this._utils.confirm(
        'Êtes-vous sûr de vouloir supprimer ?'
      );
      if (success) {
        this.isLoading = true;
        try {
          this.data.levees.splice(index, 1);
          this.data.item.statut = this._utils.getStatut(this.data.item.fichierJustificatif);
          if (this.data.autre) {
            await this._etablissementAutreJustificatifService.update(this.data.item as EtablissementAutreJustificatif).toPromise();
          } else {
            await this._etablissementJustificatifService.update(this.data.item as EtablissementJustificatif).toPromise();
          }
          this.isLoading = false;
          this.dialogRef.close(true)
        } catch (e) {
          this._toasterService.error();
          this.isLoading = false;
        }
      }
    } catch (e) {}
  }

}
